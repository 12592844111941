<template>
  <div class="userCheck">
    <hearder-input
      @getUserList="getUserList"
      title="请输入用户微信用户id"
    ></hearder-input>
    <template>
      <el-table
        :data="userList"
        style="width: 100%"
        :header-cell-style="headClass"
      >
        <el-table-column prop="wxUserId" width="100px" label="用户id"></el-table-column>
        <el-table-column prop="userName" width="100px" label="昵称"></el-table-column>
        <el-table-column prop="gender" width="100px" label="性别">
          <template slot-scope="scope">{{
            scope.row.gender === 1 ? "男" : "女"
          }}</template>
        </el-table-column>
        <el-table-column prop="emotionalExp" label="情感经历"> </el-table-column>
        
        <el-table-column label="状态" width="100px">
          <template slot-scope="scope">
            <span>待审核</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200px" align="center">
          <template slot-scope="scope">
            <div class="operation_group">
              <el-button type="primary" @click="handleCheck(scope.row, 1)">通过</el-button>
              <el-button type="primary" @click="handleCheck(scope.row, -1)">不通过</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div class="pagination">
      <pagination
        :total="total"
        :size="queryInfo.size"
        :page="queryInfo.page"
        @selectSizeChange="selectSizeChange"
        @selectPageChange="selectPageChange"
      ></pagination>
    </div>
  </div>
</template>

<script>
import { request } from "../../API/request";
import hearderInput from "../../components/hearderInput/hearderInput.vue";
import Pagination from "../../components/pagination/pagination.vue";


export default {
  name: '',
  components: { hearderInput, Pagination },

  data () {
    return {
      userList: [],
      queryInfo: {
        page: 1, //当前页数
        size: 10, //一页显示多个条数据
      },
      // 分页的总条数
      total: 0,
      principal: "", // 负责人
      principalList: [], // 负责人列表
    }
  },
  created () {
    this.getUserList()
  },
  methods: {
    // 修改表头的样式
    headClass() {
      return "background:#fff7f7;";
    },
    getUserList (e) {
      request({
        url: "/userInfo/page",
        // url: "/wxCustomer/page",
        method: "GET",
        params: {
          page: this.queryInfo.page,
          size: this.queryInfo.size,
          exp: 0,
          wxUserId: e
        },
      }).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.userList = res.data.records;
          this.total = res.data.total;
        } else {
          this.$message.error("获取数据失败");
        }
      });
    },
    handleCheck (item, status) {
      console.log(item);
      let that = this;
      let text = ''
      if (status === 1) {
        text = '审核通过'
      }
      if (status === -1) {
        text = '审核不通过'
      }
      that
        .$confirm(text, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "error",
        })
        .then(() => {
          setTimeout(() => {
            request({
              url: "/userInfo/updateExp",
              method: "POST",
              data: {
                exp: status,
                wxUserId: item.wxUserId
              },
            }).then((res) => {
              if (res.code === 200) {
                that.$message.success("修改审核状态成功！");
                that.getUserList()
              } else {
                this.$message.error(res.message);
              }
            });
          }, 10);
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    // 分页中每页显示几条所触发的回调
    selectSizeChange(e) {
      this.queryInfo.size = e;
      this.getUserList();
    },
    // 当前第几页所触发的回调
    selectPageChange(e) {
      this.queryInfo.page = e;
      this.getUserList();
    }
  }
}
</script>

<style scoped lang=less>
.userCheck {
  background-color: #ffffff;
  padding: 24px;
  height: 93%;
  position: relative;
}
.el-table {
  margin-top: 24px;
}
.operation_group {
  color: #155bd4;
  cursor: pointer;
  display: flex;
}
.isTitle {
  font-size: var(--mainSize);
  text-align: center;
  margin-bottom: 12px;
}
.content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 12px;
  font-size: var(--subSize);
  .leftText {
    width: 100px;
  }
  .upPhotoList {
    position: relative;
    margin: 20px 20px 0 0;
    .close {
      position: absolute;
      top: -12px;
      right: -12px;
      .close_icon {
        width: 24px;
        height: 24px;
      }
    }
  }
  .cardList {
    display: flex;
    flex-wrap: wrap;
  }
  .video {
    width: 180px;
    height: 300px;
    display: block;
  }
  .avatar-uploader {
    margin-top: 20px;
  }
  .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    &:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      border: 1px dashed #999999;
      font-size: 28px;
      color: #999999;
      width: 105px;
      height: 105px;
      line-height: 105px;
      text-align: center;
    }
  }
}
.avatar {
  width: 105px;
  height: 105px;
  display: block;
}
.btn {
  width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  .affirm {
    background: var(--pink);
    color: var(--white);
  }
}

</style>